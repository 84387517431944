<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title class="">
            Profile Details
            <v-spacer></v-spacer>
             <div class="mt-5 px-2">
              <v-btn outlined color="red" @click="edit(user._id)">Edit Profile</v-btn>
            </div>
          </v-card-title>
          <div v-if="user">
            <v-row
              class="py-2 justify-content-md-center"
              style="background-color: #b0a7a8"
            >
              <v-col cols="12" class="pa-10">
                <v-row>
                  <v-col cols="12" md="2" sm="12">
                    <div v-viewer>
                      <v-avatar
                        style="cursor: pointer"
                        v-if="user.image"
                        size="150"
                      >
                        <img :src="baseURL + user.image" />
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col cols="12"  md="10" sm="12" class="pa-10" style="">
                    <h1 style="color: red">
                      {{ user.firstname }} {{ user.lastname }}
                    </h1>
                    <h5 style="color: red">{{ user.email }}</h5>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">First Name</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.firstname }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Last Name</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.lastname }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Username</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.username }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Gender</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.gender }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Phone</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.phone }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="4" md="5">
                    <span class="item_title">Email</span>
                  </v-col>
                  <v-col cols="8" md="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.email }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">User Status</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.status }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Role</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2">{{ user.type }}</span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="5">
                    <span class="item_title">Account Created On</span>
                  </v-col>
                  <v-col cols="7">
                    <span class="item_value">-</span>
                    <span class="item_value ma-2"
                      >{{ formatDate(user.account_create_at) }}
                    </span>
                  </v-col>
                </v-row>
                <hr />
              </v-col>
            </v-row>
          </div>
          <v-row class="justify-center">
            <!-- <div class="mt-5 px-2">
              <v-btn outlined color="red" @click="change()"
                >Change Password</v-btn
              >
            </div> -->
          </v-row>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      ServerError: false,
      user: [],
      appLoading: false,
      userStatus: null,
      userRole: localStorage.getItem("role"),
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.user = response.data.data;
            // this.userStatus = this.user.status;
            console.log(this.user);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
    edit(aid) {
      this.$router.push({ name: "editProfile", params: { adminid: aid } });
    },
    change() {
      this.$router.push("/changePassword");
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 14px;
}
.item_value {
  font-size: 13px;
}
</style>